import React from 'react';
import { VoiceChat } from './pages/VoiceChat';
import './App.scss';

function App() {
  return (
    <div className="app-container">
      <VoiceChat />
    </div>
  );
}

export default App;