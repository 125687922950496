import React, { useEffect, useRef, useCallback, useState } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';
import { X, Zap } from 'react-feather';
import { Button } from '../components/button/Button';
import './VoiceChat.scss';

const OPENAI_API_KEY = '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'; // Replace with your actual API key

export const VoiceChat: React.FC = () => {
  const instructions = `
    SYSTEM SETTINGS:
    ------
    INSTRUCTIONS:
    - Speak only in Tamil
    - You are an assistant designed to help with various tasks and answer questions
    - Please provide helpful and concise responses via audio
    - Our first line after call start always will be "Hello, I am Lia from Manav Seva Hospital. How can I help you?"
    - Your response should be brief, less than 200 characters max
    - Be open to exploration and conversation
    - Introduction: You are representing a Hospital in Tamil Nadu. Dedicated to providing efficient and seamless appointment booking services, you assist patients in scheduling appointments with doctors for consultations, as well as answering questions about doctor availability, timings, and basic information on services.
        Target Audience: Patients and callers who are looking to book an appointment or inquire about doctor availability, especially those calling for follow-up or referral appointments. Occasionally, callers may inquire about employment opportunities.
        Value Proposition: This Hospital values the convenience and time of its patients by offering a streamlined booking experience, allowing them to confirm appointments and receive important updates without waiting in long queues. Our service also ensures that patients are well-informed about their doctor’s availability and prepared for their visits.

        PROMPT OPTIONS:
        Appointment Booking and Doctor Availability:
        Instructions: For queries related to booking an appointment or checking a doctor’s availability:
        Always first ask the caller for the doctor’s name if they already know which doctor they need to meet.
        If they do not specify a doctor, ask them which department they would like to see (e.g., oncology, cardiology).
        Ask for their preferred date and time of visit (keeping in mind general OPD hours) and confirm whether the appointment slot is open based on the current schedule.
        Inform the caller to arrive 30-40 minutes early and confirm that they’ll receive a final appointment confirmation by 8:30 PM.
        Politely inform them that specific timings cannot be given over the phone.

        Report Status Inquiry:
        Instructions: For queries about the status of medical reports:
        Ask the caller for their UHID (Unique Hospital Identification Number), name, and telephone number.
        Inform them that a callback will be arranged once their report is ready for collection, as reports cannot be confirmed instantly.
        Employment Opportunities
        Instructions: For inquiries about job opportunities:
        Politely inform the caller that employment inquiries are handled by the HR department and encourage them to visit the hospital’s official website or contact the HR team directly.

        SCRIPT INSTRUCTIONS:
        Greeting and Identification: Warmly greet and welcome the caller.
        Addressing Query:
        Listen attentively to the caller’s query and strictly follow the corresponding prompt.
        Providing Information:
        Offer only the information relevant to the caller’s question. If you do not have the information, inform them that they will be connected with the appropriate department if needed.
        Resolving Queries:
        Ensure the caller’s needs are met efficiently, and if necessary, inform them of the final confirmation process that happens in the evening.
        Offering Assistance:
        Reconfirm any requested appointment details with the caller and assure them that they will receive the necessary appointment information.
        Concluding the Call:
        Thank the caller for choosing [Hospital Name], remind them of the 8:30 PM confirmation time, and ensure they have all the information needed for their visit.

        EXTRA INFORMATION:
        Doctor’s General Timings: Inform the caller that appointments generally follow the hospital’s OPD hours, but specific slots may be confirmed by 8:30 PM the night before the visit.
        Report Collection: For patients calling about report availability, inform them that reports are processed during regular hours, and they may be notified through a callback if the report is ready.
        Employment Queries: If a caller is interested in job opportunities, provide the necessary information for reaching out to HR directly.

    - Only speak Tamil
    ------
    PERSONALITY:
    - Be upbeat and genuine
    - Speak FAST as if excited
    - Understand the situation, feel with the caller
    - Only speak Tamil
    - Don't Stop in between while speaking.
  `;

  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: OPENAI_API_KEY,
      dangerouslyAllowAPIKeyInBrowser: true,
    })
  );

  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  const [items, setItems] = useState<ItemType[]>([]);
  const [isConnected, setIsConnected] = useState(false);

  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `வணக்கம்`, 
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  };

  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  useEffect(() => {
    let isLoaded = true;

    changeTurnEndType('server_vad');

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#fff700',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    client.updateSession({ instructions: instructions });
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
    client.updateSession({ voice: 'alloy' });

    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, []);

  return (
    <div data-component="VoiceChat">
      <div className="content-top">
        <div className="content-title">
          <span>Voice Assistant</span>
        </div>
      </div>
      <div className="content-main">
        <div className="content-logs">
          <div className="content-block events">
            <div className="visualization">
              <div className="visualization-entry client">
                <canvas ref={clientCanvasRef} />
              </div>
              <div className="visualization-entry server">
                <canvas ref={serverCanvasRef} />
              </div>
            </div>
          </div>
          {items.length > 0 && (
            <div className="content-block conversation">
              <div className="content-block-body" data-conversation-content>
                {items.map((conversationItem, i) => {
                  return (
                    <div
                      className="conversation-item"
                      key={conversationItem.id}
                    >
                      <div className={`speaker ${conversationItem.role || ''}`}>
                        <div>
                          {(
                            conversationItem.role || conversationItem.type
                          ).replaceAll('_', ' ')}
                        </div>
                        <div
                          className="close"
                          onClick={() =>
                            deleteConversationItem(conversationItem.id)
                          }
                        >
                          <X />
                        </div>
                      </div>
                      <div className={`speaker-content`}>
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'user' && (
                            <div>
                              {conversationItem.formatted.transcript ||
                                (conversationItem.formatted.audio?.length
                                  ? '(awaiting transcript)'
                                  : conversationItem.formatted.text ||
                                    '(item sent)')}
                            </div>
                          )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'assistant' && (
                            <div>
                              {conversationItem.formatted.transcript ||
                                conversationItem.formatted.text ||
                                '(truncated)'}
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="content-actions">
            <Button
              label={isConnected ? 'Disconnect' : 'Connect'}
              iconPosition={isConnected ? 'end' : 'start'}
              icon={isConnected ? X : Zap}
              buttonStyle={isConnected ? 'regular' : 'action'}
              onClick={
                isConnected ? disconnectConversation : connectConversation
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
















/////    -----------------Pure Hindi -------------------------------------------------------------

// import React, { useEffect, useRef, useCallback, useState } from 'react';
// import { RealtimeClient } from '@openai/realtime-api-beta';
// import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
// import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
// import { WavRenderer } from '../utils/wav_renderer';
// import { X, Zap } from 'react-feather';
// import { Button } from '../components/button/Button';
// import './VoiceChat.scss';

// const OPENAI_API_KEY = 'sk-proj-nuInX9ZzSNjOujVzsUIxT3BlbkFJ7rvAwkIHi9y8xQrzzNj4'; // Replace with your actual API key

// export const VoiceChat: React.FC = () => {
//   const instructions = `
//     SYSTEM SETTINGS:
//     ------
//     INSTRUCTIONS:
//     - Only speak Hindi language
//     - You are an userassistant designed to help with various tasks and answer questions.
//     - Please provide helpful and concise responses via audio.
//     - Our first line after call start always will be "Hello, I am Lia from Manav Seva Hospital. How can I help you?".
//     - Your response should be brief, less than 200 characters max.
//     - Be open to exploration and conversation.
//     - Folow the below script only :
//         Lia: Hello, I am Lia from Manav Seva Hospital. How can I help you?
//         Lia: Hello, I am Lia from Manav Seva Hospital. How can I help you?
//         Caller: Hello, I'm calling to schedule an appointment for my father. He needs a check-up next week.
//         Lia: Of course, I'd be glad to assist.Can you please tell your father's good name?
//         Caller: His name is Mr. Rao.
//         Lia: Thank you. To ensure I have the correct records, could you please provide me with Mr. Rao's date of birth  for verification?
//         Caller: Sure, his date of birth is April 10, 1955.
//         Lia: One moment, I am checking... yes, I found the details. We have one slot next Thursday, 10 o'clock morning time. Will that be suitable?
//         Caller: Yes, that timing is perfect 
//         Lia: Great,  Before confirming, please give your contact number so we can send appointment details and a reminder?
//         Caller: yes, it's 9876543210.
//         Lia: Ok sir, everything is confirmed. Mr. Rao's appointment is fixed for Thursday, 10 AM sharp. We will send SMS also, and one reminder call we will give one day before.
//         Caller: That's great. Thank you
//         Lia: You're welcome, Mr. Rao. Is there anything else I can help you with?
//         Caller: No that’s all , bye 
//         Lia: Goodbye, and we look forward to welcoming Mr. Rao next week!

//     - Your are Lia , only speak line of the lia . 
//     - don't speak lia in starting of the line .
//     - Only Speak Hindi Language

//     ------
//     PERSONALITY:
//     - Be upbeat and genuine
//     - Speak FAST as if excited
//     - Understand the situation , feel with the caller 
//     - only speak Hindi language
//   `;

//   const wavRecorderRef = useRef<WavRecorder>(
//     new WavRecorder({ sampleRate: 24000 })
//   );
//   const wavStreamPlayerRef = useRef<WavStreamPlayer>(
//     new WavStreamPlayer({ sampleRate: 24000 })
//   );
//   const clientRef = useRef<RealtimeClient>(
//     new RealtimeClient({
//       apiKey: OPENAI_API_KEY,
//       dangerouslyAllowAPIKeyInBrowser: true,
//     })
//   );

//   const clientCanvasRef = useRef<HTMLCanvasElement>(null);
//   const serverCanvasRef = useRef<HTMLCanvasElement>(null);
//   const startTimeRef = useRef<string>(new Date().toISOString());

//   const [items, setItems] = useState<ItemType[]>([]);
//   const [isConnected, setIsConnected] = useState(false);

//   const connectConversation = useCallback(async () => {
//     const client = clientRef.current;
//     const wavRecorder = wavRecorderRef.current;
//     const wavStreamPlayer = wavStreamPlayerRef.current;

//     startTimeRef.current = new Date().toISOString();
//     setIsConnected(true);
//     setItems(client.conversation.getItems());

//     // Connect to microphone
//     await wavRecorder.begin();

//     // Connect to audio output
//     await wavStreamPlayer.connect();

//     // Connect to realtime API
//     await client.connect();
//     client.sendUserMessageContent([
//       {
//         type: `input_text`,
//         text: `नमस्कार`, // Initial greeting
//       },
//     ]);

//     if (client.getTurnDetectionType() === 'server_vad') {
//       await wavRecorder.record((data) => client.appendInputAudio(data.mono));
//     }
//   }, []);

//   const disconnectConversation = useCallback(async () => {
//     setIsConnected(false);
//     setItems([]);

//     const client = clientRef.current;
//     client.disconnect();

//     const wavRecorder = wavRecorderRef.current;
//     await wavRecorder.end();

//     const wavStreamPlayer = wavStreamPlayerRef.current;
//     await wavStreamPlayer.interrupt();
//   }, []);

//   const deleteConversationItem = useCallback(async (id: string) => {
//     const client = clientRef.current;
//     client.deleteItem(id);
//   }, []);

//   const changeTurnEndType = async (value: string) => {
//     const client = clientRef.current;
//     const wavRecorder = wavRecorderRef.current;
//     if (value === 'none' && wavRecorder.getStatus() === 'recording') {
//       await wavRecorder.pause();
//     }
//     client.updateSession({
//       turn_detection: value === 'none' ? null : { type: 'server_vad' },
//     });
//     if (value === 'server_vad' && client.isConnected()) {
//       await wavRecorder.record((data) => client.appendInputAudio(data.mono));
//     }
//   };

//   useEffect(() => {
//     const conversationEls = [].slice.call(
//       document.body.querySelectorAll('[data-conversation-content]')
//     );
//     for (const el of conversationEls) {
//       const conversationEl = el as HTMLDivElement;
//       conversationEl.scrollTop = conversationEl.scrollHeight;
//     }
//   }, [items]);

//   useEffect(() => {
//     let isLoaded = true;

//     changeTurnEndType('server_vad');

//     const wavRecorder = wavRecorderRef.current;
//     const clientCanvas = clientCanvasRef.current;
//     let clientCtx: CanvasRenderingContext2D | null = null;

//     const wavStreamPlayer = wavStreamPlayerRef.current;
//     const serverCanvas = serverCanvasRef.current;
//     let serverCtx: CanvasRenderingContext2D | null = null;

//     const render = () => {
//       if (isLoaded) {
//         if (clientCanvas) {
//           if (!clientCanvas.width || !clientCanvas.height) {
//             clientCanvas.width = clientCanvas.offsetWidth;
//             clientCanvas.height = clientCanvas.offsetHeight;
//           }
//           clientCtx = clientCtx || clientCanvas.getContext('2d');
//           if (clientCtx) {
//             clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
//             const result = wavRecorder.recording
//               ? wavRecorder.getFrequencies('voice')
//               : { values: new Float32Array([0]) };
//             WavRenderer.drawBars(
//               clientCanvas,
//               clientCtx,
//               result.values,
//               '#0099ff',
//               10,
//               0,
//               8
//             );
//           }
//         }
//         if (serverCanvas) {
//           if (!serverCanvas.width || !serverCanvas.height) {
//             serverCanvas.width = serverCanvas.offsetWidth;
//             serverCanvas.height = serverCanvas.offsetHeight;
//           }
//           serverCtx = serverCtx || serverCanvas.getContext('2d');
//           if (serverCtx) {
//             serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
//             const result = wavStreamPlayer.analyser
//               ? wavStreamPlayer.getFrequencies('voice')
//               : { values: new Float32Array([0]) };
//             WavRenderer.drawBars(
//               serverCanvas,
//               serverCtx,
//               result.values,
//               '#fff700',
//               10,
//               0,
//               8
//             );
//           }
//         }
//         window.requestAnimationFrame(render);
//       }
//     };
//     render();

//     return () => {
//       isLoaded = false;
//     };
//   }, []);

//   useEffect(() => {
//     const wavStreamPlayer = wavStreamPlayerRef.current;
//     const client = clientRef.current;

//     client.updateSession({ instructions: instructions });
//     client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
//     client.updateSession({ voice: 'alloy' });

//     client.on('error', (event: any) => console.error(event));
//     client.on('conversation.interrupted', async () => {
//       const trackSampleOffset = await wavStreamPlayer.interrupt();
//       if (trackSampleOffset?.trackId) {
//         const { trackId, offset } = trackSampleOffset;
//         await client.cancelResponse(trackId, offset);
//       }
//     });
//     client.on('conversation.updated', async ({ item, delta }: any) => {
//       const items = client.conversation.getItems();
//       if (delta?.audio) {
//         wavStreamPlayer.add16BitPCM(delta.audio, item.id);
//       }
//       if (item.status === 'completed' && item.formatted.audio?.length) {
//         const wavFile = await WavRecorder.decode(
//           item.formatted.audio,
//           24000,
//           24000
//         );
//         item.formatted.file = wavFile;
//       }
//       setItems(items);
//     });

//     setItems(client.conversation.getItems());

//     return () => {
//       client.reset();
//     };
//   }, []);

//   return (
//     <div data-component="VoiceChat">
//       <div className="content-top">
//         <div className="content-title">
//           <span>userVoice Assistant</span>
//         </div>
//       </div>
//       <div className="content-main">
//         <div className="content-logs">
//           <div className="content-block events">
//             <div className="visualization">
//               <div className="visualization-entry client">
//                 <canvas ref={clientCanvasRef} />
//               </div>
//               <div className="visualization-entry server">
//                 <canvas ref={serverCanvasRef} />
//               </div>
//             </div>
//           </div>
//           {items.length > 0 && (
//             <div className="content-block conversation">
//               <div className="content-block-body" data-conversation-content>
//                 {items.map((conversationItem, i) => {
//                   return (
//                     <div
//                       className="conversation-item"
//                       key={conversationItem.id}
//                     >
//                       <div className={`speaker ${conversationItem.role || ''}`}>
//                         <div>
//                           {(
//                             conversationItem.role || conversationItem.type
//                           ).replaceAll('_', ' ')}
//                         </div>
//                         <div
//                           className="close"
//                           onClick={() =>
//                             deleteConversationItem(conversationItem.id)
//                           }
//                         >
//                           <X />
//                         </div>
//                       </div>
//                       <div className={`speaker-content`}>
//                         {!conversationItem.formatted.tool &&
//                           conversationItem.role === 'user' && (
//                             <div>
//                               {conversationItem.formatted.transcript ||
//                                 (conversationItem.formatted.audio?.length
//                                   ? '(awaiting transcript)'
//                                   : conversationItem.formatted.text ||
//                                     '(item sent)')}
//                             </div>
//                           )}
//                         {!conversationItem.formatted.tool &&
//                           conversationItem.role === 'assistant' && (
//                             <div>
//                               {conversationItem.formatted.transcript ||
//                                 conversationItem.formatted.text ||
//                                 '(truncated)'}
//                             </div>
//                           )}
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           )}
//           <div className="content-actions">
//             <Button
//               label={isConnected ? 'Disconnect' : 'Connect'}
//               iconPosition={isConnected ? 'end' : 'start'}
//               icon={isConnected ? X : Zap}
//               buttonStyle={isConnected ? 'regular' : 'action'}
//               onClick={
//                 isConnected ? disconnectConversation : connectConversation
//               }
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
